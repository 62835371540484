import React, { Component, Fragment } from "react";
import { Switch } from "react-router";
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  withRouter,
} from "react-router-dom";
import * as axios from "axios";

import { LoginForm } from "./components/login";
import { MainPage } from "./components/main-page";
import { HomePage } from "./components/home-page";
import { ProfilePage } from "./components/profile-page";
import { RankingPage } from "./components/ranking-page";
import { Chat } from "./components/chat";
import { ConfigForm } from "./components/config";
import {
  doLogout,
  getLoginInfoWithCallback,
  setStateInfo,
  getRankingWithCallback,
  getMessages,
} from "./data/session";

import "./App.css";
import "./App-responsive.css";
import "./components/comments.css";
import "./components/form.css";

import LogoTemTorcida from "./images/logo-tem-torcida-2022.png";
import LogoTvTemBlue from "./images/logo_tvtem_blue_2022.png";
import LogoFifa2022 from "./images/logo-fifa-2022.png";

class App extends Component {
  constructor(props) {
    super(props);

    let messages = getMessages();
    this.state = {
      user: null,
      ranking: [],
      messages: messages,
    };

    getLoginInfoWithCallback(this.onUserChanged.bind(this));
    getRankingWithCallback(this.onRankingChanged.bind(this));

    this.refreshState();

    this.navigateToRanking = this.navigateToRanking.bind(this);
  }

  refreshState() {
    axios.post("/api/state").then((r) => {
      if (r.status !== 200) return;

      let d = r.data;
      if (d.status === "OK") setStateInfo(d);

      if (d.status !== "OK" || !d.user) {
        let isPublicUrl = window.location.pathname.match(
          /^\/(cadastro|signup\/.+)?$/
        );
        if (!isPublicUrl) this.props.history.push("/login");
        return;
      }
    });
  }

  onUserChanged(user) {
    this.setState({ user: user });
  }

  onRankingChanged(ranking) {
    let messages = getMessages();

    this.setState({
      ranking: ranking,
      messages: messages,
    });
  }

  navigateToRanking() {
    let history = this.props.history;
    history.push("/ranking");
  }

  render() {
    let history = this.props.history;
    let loggedUser = this.state.user;
    let ranking = this.state.ranking;

    let message = this.state.messages.message;
    let footer = this.state.messages.footer;

    return (
      <div className="App">
        <header className="App-header">
          <div className="container">
            <img src={LogoFifa2022} className="App-logo r" alt="logo" />

            <NavLink to="/" className="main-logos">
              <img src={LogoTemTorcida} className="App-logo" alt="logo" />
              <img src={LogoTvTemBlue} className="App-logo-small" alt="logo" />
            </NavLink>
          </div>
        </header>

        <div>
          <div className="container">
            <div className="App-intro">
              {loggedUser ? (
                <div className="user-info">
                  <div className="user">
                    {loggedUser.fullName}
                    <br />
                    <NavLink to="/profile">Perfil</NavLink>
                    {loggedUser.isAdmin && (
                      <NavLink to="/resultados">Resultados</NavLink>
                    )}
                    <a href="/logout" onClick={(e) => doLogout(history, e)}>
                      Sair
                    </a>
                  </div>
                </div>
              ) : null}
              {message}&nbsp;
            </div>
          </div>
        </div>

        <div className="main-content">
          <div>
            <aside className="side-bar">
              <div className="sticky-bar">
                <Switch>
                  <Route path="/ranking" exact />
                  <Route>
                    {ranking.length === 0 ? null : (
                      <Fragment>
                        <h1 className="group-title ranking">
                          <button
                            className="bt pull-right grp grey"
                            title="Exibir ranking completo"
                            onClick={this.navigateToRanking}
                          >
                            &nbsp;
                          </button>
                          Ranking
                        </h1>
                        <ul className="ranking-list">
                          {ranking.map((user) => (
                            <li key={`user_${user.id}`}>
                              <b>{user.score} pontos</b>
                              <i>{`${user.position}. `}</i>
                              {user.name}
                            </li>
                          ))}
                        </ul>
                      </Fragment>
                    )}
                  </Route>
                </Switch>

                <Chat />
              </div>
            </aside>

            <div className="container">
              <Switch>
                <Route path="/config" component={ConfigForm} />
                <Route
                  path="/cadastro"
                  exact
                  render={(props) => <LoginForm mode={4} {...props} />}
                />
                <Route path="/login" component={LoginForm} />
                <Route path="/profile" exact component={ProfilePage} />
                <Route path="/ranking" exact component={RankingPage} />
                <Route path="/signup/:uid/:key" component={LoginForm} />
                <Route
                  path="/profile/change_password"
                  exact
                  render={(props) => <LoginForm mode={3} />}
                />
                <Route path="/palpites" exact component={MainPage} />
                <Route
                  path="/resultados"
                  exact
                  render={() => <MainPage resultados="1" />}
                />
                <Route
                  path="/regulamento"
                  exact
                  render={(p) => (
                    <HomePage
                      {...p}
                      userLoggedIn={!!loggedUser}
                      message={this.state.messages.rulesContent}
                      showBackButton={true}
                    />
                  )}
                />
                <Route
                  path="/"
                  exact
                  render={(p) => (
                    <HomePage
                      {...p}
                      userLoggedIn={!!loggedUser}
                      message={this.state.messages.homeContent}
                    />
                  )}
                />
              </Switch>
            </div>
          </div>
        </div>

        <div className="App-footer">
          <div className="container">{footer}</div>
        </div>
      </div>
    );
  }
}

const AppInternal = withRouter(App);
const AppWithRouter = (props) => (
  <Router>
    <AppInternal />
  </Router>
);

export default AppWithRouter;
