// https://raw.githubusercontent.com/lsv/fifa-worldcup-2018/master/data.json

import * as moment from "moment";
import "moment/locale/pt-br.js";

const WORLD_CUP_QATAR2022 = {
  stadiums: [
    {
      id: 1,
      name: "Lusail Iconic Stadium",
      city: "Lusail",
      lat: 55.715765,
      lng: 37.5515217,
    },
    {
      id: 2,
      name: "Al Bayt Stadium",
      city: "Al Khor",
      lat: 55.817765,
      lng: 37.440363,
    },
    {
      id: 3,
      name: "Stadium 974",
      city: "Doha",
      lat: 59.97274,
      lng: 30.221408,
    },
    {
      id: 4,
      name: "Al Thumama Stadium",
      city: "Doha",
      lat: 54.698157,
      lng: 20.533859,
    },
    {
      id: 5,
      name: "Khalifa International Stadium",
      city: "Al Rayyan",
      lat: 55.820983,
      lng: 49.160966,
    },
    {
      id: 6,
      name: "Education City Stadium",
      city: "Al Rayyan",
      lat: 56.337287,
      lng: 43.963251,
    },
    {
      id: 7,
      name: "Ahmad bin Ali Stadium",
      city: "Al Rayyan",
      lat: 53.278146,
      lng: 50.238854,
    },
    {
      id: 8,
      name: "Al Janoub Stadium",
      city: "Al Wakrah",
      lat: 48.734195,
      lng: 44.548345,
    },
  ],

  teams: [
    {
      id: 1,
      name: "Qatar",
      iso2: "qa",
      nome: "Catar",
    },
    {
      id: 2,
      name: "Ecuador",
      iso2: "ec",
      nome: "Equador",
    },
    {
      id: 3,
      name: "Senegal",
      iso2: "sn",
      nome: "Senegal",
    },
    {
      id: 4,
      name: "Netherlands",
      iso2: "nl",
      nome: "Holanda",
    },
    {
      id: 5,
      name: "England",
      iso2: "gb-eng",
      nome: "Inglaterra",
    },
    {
      id: 6,
      name: "Iran",
      iso2: "ir",
      nome: "Irã",
    },
    {
      id: 7,
      name: "United States",
      iso2: "us",
      nome: "Estados Unidos",
    },
    {
      id: 8,
      name: "Wales",
      iso2: "gb-wls",
      nome: "País de Gales",
    },
    {
      id: 9,
      name: "Argentina",
      iso2: "ar",
      nome: "Argentina",
    },
    {
      id: 10,
      name: "Saudi Arabia",
      iso2: "sa",
      nome: "Arábia Saudita",
    },
    {
      id: 11,
      name: "Mexico",
      iso2: "mx",
      nome: "México",
    },
    {
      id: 12,
      name: "Poland",
      iso2: "pl",
      nome: "Polônia",
    },
    {
      id: 13,
      name: "France",
      iso2: "fr",
      nome: "França",
    },
    {
      id: 14,
      name: "Australia",
      iso2: "au",
      nome: "Austrália",
    },
    {
      id: 15,
      name: "Denmark",
      iso2: "dk",
      nome: "Dinamarca",
    },
    {
      id: 16,
      name: "Tunisia",
      iso2: "tn",
      nome: "Tunísia",
    },
    {
      id: 17,
      name: "Spain",
      iso2: "es",
      nome: "Espanha",
    },
    {
      id: 18,
      name: "Costa Rica",
      iso2: "cr",
      nome: "Costa Rica",
    },
    {
      id: 19,
      name: "Germany",
      iso2: "de",
      nome: "Alemanha",
    },
    {
      id: 20,
      name: "Japan",
      iso2: "jp",
      nome: "Japão",
    },
    {
      id: 21,
      name: "Belgium",
      iso2: "be",
      nome: "Bélgica",
    },
    {
      id: 22,
      name: "Canada",
      iso2: "ca",
      nome: "Canadá",
    },
    {
      id: 23,
      name: "Morocco",
      iso2: "ma",
      nome: "Marrocos",
    },
    {
      id: 24,
      name: "Croatia",
      iso2: "hr",
      nome: "Croácia",
    },
    {
      id: 25,
      name: "Brazil",
      iso2: "br",
      nome: "Brasil",
    },
    {
      id: 26,
      name: "Serbia",
      iso2: "rs",
      nome: "Sérvia",
    },
    {
      id: 27,
      name: "Switzerland",
      iso2: "ch",
      nome: "Suíça",
    },
    {
      id: 28,
      name: "Cameroon",
      iso2: "cm",
      nome: "Camarões",
    },
    {
      id: 29,
      name: "Portugal",
      iso2: "pt",
      nome: "Portugal",
    },
    {
      id: 30,
      name: "Ghana",
      iso2: "gh",
      nome: "Gana",
    },
    {
      id: 31,
      name: "Uruguay",
      iso2: "uy",
      nome: "Uruguai",
    },
    {
      id: 32,
      name: "South Korea",
      iso2: "kr",
      nome: "Coréia do Sul",
    },
  ],
  groups: {
    a: {
      winner: null,
      runnerup: null,
      matches: [
        {
          match_id: 1,
          name: 1,
          type: "group",
          home_team: 1,
          away_team: 2,
          home_result: null,
          away_result: null,
          date: "2022-11-20T19:00:00+03:00",
          stadium: 2,
          finished: false,
        },
        {
          match_id: 2,
          name: 2,
          type: "group",
          home_team: 3,
          away_team: 4,
          home_result: null,
          away_result: null,
          date: "2022-11-21T19:00:00+03:00",
          stadium: 4,
          finished: false,
        },
        {
          match_id: 18,
          name: 17,
          type: "group",
          home_team: 1,
          away_team: 3,
          home_result: null,
          away_result: null,
          date: "2022-11-25T16:00:00+03:00",
          stadium: 4,
          finished: false,
        },
        {
          match_id: 19,
          name: 18,
          type: "group",
          home_team: 4,
          away_team: 2,
          home_result: null,
          away_result: null,
          date: "2022-11-25T19:00:00+03:00",
          stadium: 5,

          finished: false,
        },
        {
          match_id: 35,
          name: 33,
          type: "group",
          home_team: 2,
          away_team: 3,
          home_result: null,
          away_result: null,
          date: "2022-11-29T18:00:00+03:00",
          stadium: 5,

          finished: false,
        },
        {
          match_id: 36,
          name: 34,
          type: "group",
          home_team: 4,
          away_team: 1,
          home_result: null,
          away_result: null,
          date: "2022-11-29T18:00:00+03:00",
          stadium: 2,

          finished: false,
        },
      ],
    },
    b: {
      winner: null,
      runnerup: null,
      matches: [
        {
          match_id: 3,
          name: 3,
          type: "group",
          home_team: 5,
          away_team: 6,
          home_result: null,
          away_result: null,
          date: "2022-11-21T16:00:00+03:00",
          stadium: 5,

          finished: false,
        },
        {
          match_id: 4,
          name: 4,
          type: "group",
          home_team: 7,
          away_team: 8,
          home_result: null,
          away_result: null,
          date: "2022-11-21T22:00:00+03:00",
          stadium: 7,

          finished: false,
        },
        {
          match_id: 17,
          name: 19,
          type: "group",
          home_team: 8,
          away_team: 6,
          home_result: null,
          away_result: null,
          date: "2022-11-25T13:00:00+03:00",
          stadium: 7,

          finished: false,
        },
        {
          match_id: 20,
          name: 20,
          type: "group",
          home_team: 5,
          away_team: 7,
          home_result: null,
          away_result: null,
          date: "2022-11-25T22:00:00+03:00",
          stadium: 2,

          finished: false,
        },
        {
          match_id: 33,
          name: 35,
          type: "group",
          home_team: 8,
          away_team: 5,
          home_result: null,
          away_result: null,
          date: "2022-11-29T22:00:00+03:00",
          stadium: 7,

          finished: false,
        },
        {
          match_id: 34,
          name: 36,
          type: "group",
          home_team: 6,
          away_team: 7,
          home_result: null,
          away_result: null,
          date: "2022-11-29T22:00:00+03:00",
          stadium: 4,

          finished: false,
        },
      ],
    },
    c: {
      winner: null,
      runnerup: null,
      matches: [
        {
          match_id: 8,
          name: 5,
          type: "group",
          home_team: 9,
          away_team: 10,
          home_result: null,
          away_result: null,
          date: "2022-11-22T13:00:00+03:00",
          stadium: 1,

          finished: false,
        },
        {
          match_id: 7,
          name: 6,
          type: "group",
          home_team: 11,
          away_team: 12,
          home_result: null,
          away_result: null,
          date: "2022-11-22T19:00:00+03:00",
          stadium: 3,

          finished: false,
        },
        {
          match_id: 22,
          name: 21,
          type: "group",
          home_team: 12,
          away_team: 10,
          home_result: null,
          away_result: null,
          date: "2022-11-26T16:00:00+03:00",
          stadium: 6,

          finished: false,
        },
        {
          match_id: 24,
          name: 22,
          type: "group",
          home_team: 9,
          away_team: 11,
          home_result: null,
          away_result: null,
          date: "2022-11-26T22:00:00+03:00",
          stadium: 1,

          finished: false,
        },
        {
          match_id: 39,
          name: 37,
          type: "group",
          home_team: 12,
          away_team: 9,
          home_result: null,
          away_result: null,
          date: "2022-11-30T22:00:00+03:00",
          stadium: 3,

          finished: false,
        },
        {
          match_id: 40,
          name: 38,
          type: "group",
          home_team: 10,
          away_team: 11,
          home_result: null,
          away_result: null,
          date: "2022-11-30T22:00:00+03:00",
          stadium: 1,

          finished: false,
        },
      ],
    },
    d: {
      winner: null,
      runnerup: null,
      matches: [
        {
          match_id: 6,
          name: 7,
          type: "group",
          home_team: 15,
          away_team: 16,
          home_result: null,
          away_result: null,
          date: "2022-11-22T16:00:00+03:00",
          stadium: 6,

          finished: false,
        },
        {
          match_id: 5,
          name: 8,
          type: "group",
          home_team: 13,
          away_team: 14,
          home_result: null,
          away_result: null,
          date: "2022-11-22T22:00:00+03:00",
          stadium: 8,

          finished: false,
        },
        {
          match_id: 21,
          name: 23,
          type: "group",
          home_team: 16,
          away_team: 14,
          home_result: null,
          away_result: null,
          date: "2022-11-26T13:00:00+03:00",
          stadium: 8,

          finished: false,
        },
        {
          match_id: 23,
          name: 24,
          type: "group",
          home_team: 13,
          away_team: 15,
          home_result: null,
          away_result: null,
          date: "2022-11-26T19:00:00+03:00",
          stadium: 3,

          finished: false,
        },
        {
          match_id: 37,
          name: 39,
          type: "group",
          home_team: 14,
          away_team: 15,
          home_result: null,
          away_result: null,
          date: "2022-11-30T18:00:00+03:00",
          stadium: 8,

          finished: false,
        },
        {
          match_id: 38,
          name: 40,
          type: "group",
          home_team: 16,
          away_team: 13,
          home_result: null,
          away_result: null,
          date: "2022-11-30T18:00:00+03:00",
          stadium: 6,

          finished: false,
        },
      ],
    },
    e: {
      winner: null,
      runnerup: null,
      matches: [
        {
          match_id: 11,
          name: 9,
          type: "group",
          home_team: 19,
          away_team: 20,
          home_result: null,
          away_result: null,
          date: "2022-11-23T16:00:00+03:00",
          stadium: 5,

          finished: false,
        },
        {
          match_id: 10,
          name: 10,
          type: "group",
          home_team: 17,
          away_team: 18,
          home_result: null,
          away_result: null,
          date: "2022-11-23T19:00:00+03:00",
          stadium: 4,

          finished: false,
        },
        {
          match_id: 25,
          name: 25,
          type: "group",
          home_team: 20,
          away_team: 18,
          home_result: null,
          away_result: null,
          date: "2022-11-27T13:00:00+03:00",
          stadium: 7,

          finished: false,
        },
        {
          match_id: 28,
          name: 26,
          type: "group",
          home_team: 17,
          away_team: 19,
          home_result: null,
          away_result: null,
          date: "2022-11-27T22:00:00+03:00",
          stadium: 2,

          finished: false,
        },
        {
          match_id: 43,
          name: 41,
          type: "group",
          home_team: 20,
          away_team: 17,
          home_result: null,
          away_result: null,
          date: "2022-12-01T22:00:00+03:00",
          stadium: 5,

          finished: false,
        },
        {
          match_id: 44,
          name: 42,
          type: "group",
          home_team: 18,
          away_team: 19,
          home_result: null,
          away_result: null,
          date: "2022-12-01T22:00:00+03:00",
          stadium: 2,

          finished: false,
        },
      ],
    },
    f: {
      winner: null,
      runnerup: null,
      matches: [
        {
          match_id: 12,
          name: 11,
          type: "group",
          home_team: 23,
          away_team: 24,
          home_result: null,
          away_result: null,
          date: "2022-11-23T13:00:00+03:00",
          stadium: 2,

          finished: false,
        },
        {
          match_id: 9,
          name: 12,
          type: "group",
          home_team: 21,
          away_team: 22,
          home_result: null,
          away_result: null,
          date: "2022-11-23T22:00:00+03:00",
          stadium: 7,

          finished: false,
        },
        {
          match_id: 26,
          name: 27,
          type: "group",
          home_team: 21,
          away_team: 23,
          home_result: null,
          away_result: null,
          date: "2022-11-27T16:00:00+03:00",
          stadium: 4,

          finished: false,
        },
        {
          match_id: 27,
          name: 28,
          type: "group",
          home_team: 24,
          away_team: 22,
          home_result: null,
          away_result: null,
          date: "2022-11-27T19:00:00+03:00",
          stadium: 5,

          finished: false,
        },
        {
          match_id: 41,
          name: 43,
          type: "group",
          home_team: 24,
          away_team: 21,
          home_result: null,
          away_result: null,
          date: "2022-12-01T18:00:00+03:00",
          stadium: 7,

          finished: false,
        },
        {
          match_id: 42,
          name: 44,
          type: "group",
          home_team: 22,
          away_team: 23,
          home_result: null,
          away_result: null,
          date: "2022-12-01T18:00:00+03:00",
          stadium: 4,

          finished: false,
        },
      ],
    },
    g: {
      winner: null,
      runnerup: null,
      matches: [
        {
          match_id: 13,
          name: 13,
          type: "group",
          home_team: 27,
          away_team: 28,
          home_result: null,
          away_result: null,
          date: "2022-11-24T13:00:00+03:00",
          stadium: 8,

          finished: false,
        },
        {
          match_id: 16,
          name: 14,
          type: "group",
          home_team: 25,
          away_team: 26,
          home_result: null,
          away_result: null,
          date: "2022-11-24T22:00:00+03:00",
          stadium: 1,

          finished: false,
        },
        {
          match_id: 29,
          name: 29,
          type: "group",
          home_team: 28,
          away_team: 26,
          home_result: null,
          away_result: null,
          date: "2022-11-28T13:00:00+03:00",
          stadium: 8,

          finished: false,
        },
        {
          match_id: 31,
          name: 30,
          type: "group",
          home_team: 25,
          away_team: 27,
          home_result: null,
          away_result: null,
          date: "2022-11-28T19:00:00+03:00",
          stadium: 3,

          finished: false,
        },
        {
          match_id: 47,
          name: 45,
          type: "group",
          home_team: 26,
          away_team: 27,
          home_result: null,
          away_result: null,
          date: "2022-12-02T22:00:00+03:00",
          stadium: 3,

          finished: false,
        },
        {
          match_id: 48,
          name: 46,
          type: "group",
          home_team: 28,
          away_team: 25,
          home_result: null,
          away_result: null,
          date: "2022-12-02T22:00:00+03:00",
          stadium: 1,

          finished: false,
        },
      ],
    },
    h: {
      winner: null,
      runnerup: null,
      matches: [
        {
          match_id: 14,
          name: 15,
          type: "group",
          home_team: 31,
          away_team: 32,
          home_result: null,
          away_result: null,
          date: "2022-11-24T16:00:00+03:00",
          stadium: 6,

          finished: false,
        },
        {
          match_id: 15,
          name: 16,
          type: "group",
          home_team: 29,
          away_team: 30,
          home_result: null,
          away_result: null,
          date: "2022-11-24T19:00:00+03:00",
          stadium: 3,

          finished: false,
        },
        {
          match_id: 30,
          name: 31,
          type: "group",
          home_team: 32,
          away_team: 30,
          home_result: null,
          away_result: null,
          date: "2022-11-28T16:00:00+03:00",
          stadium: 6,

          finished: false,
        },
        {
          match_id: 32,
          name: 32,
          type: "group",
          home_team: 29,
          away_team: 31,
          home_result: null,
          away_result: null,
          date: "2022-11-28T22:00:00+03:00",
          stadium: 1,

          finished: false,
        },
        {
          match_id: 45,
          name: 47,
          type: "group",
          home_team: 30,
          away_team: 31,
          home_result: null,
          away_result: null,
          date: "2022-12-02T18:00:00+03:00",
          stadium: 8,

          finished: false,
        },
        {
          match_id: 46,
          name: 48,
          type: "group",
          home_team: 32,
          away_team: 29,
          home_result: null,
          away_result: null,
          date: "2022-12-02T18:00:00+03:00",
          stadium: 6,

          finished: false,
        },
      ],
    },
  },
};

var QATAR2022_TEAMS_BY_ID = {};
WORLD_CUP_QATAR2022.teams.forEach((t) => {
  QATAR2022_TEAMS_BY_ID[t.id] = t;
});

var QATAR2022_STADIUMS_BY_ID = {};
WORLD_CUP_QATAR2022.stadiums.forEach((t) => {
  QATAR2022_STADIUMS_BY_ID[t.id] = t;
});

// DATE
var QATAR2022_MATCHES_BY_ID = {};
var QATAR2022_MATCHES_BY_DATE = [];
const sortByDate = (a, b) => {
  let dtA = a["dateParsed"];
  let dtB = b["dateParsed"];

  if (dtA.isBefore(dtB)) return -1;
  if (dtA.isAfter(dtB)) return 1;
  return 0;
};

const buildMatchesByDate = () => {
  let dtEntries = {};

  for (let groupKey in WORLD_CUP_QATAR2022.groups) {
    let group = WORLD_CUP_QATAR2022.groups[groupKey];

    group.matches.forEach((match) => {
      let dt = moment(match.date).startOf("day");
      let dtKey = dt.format("YYYY-MM-DD");

      let dtEntry = dtEntries[dtKey];
      if (!dtEntry) {
        dtEntry = {
          date: dt.toISOString(),
          dateParsed: dt,
          dateKey: dt.format("YYYYMMDD"),
          matches: [],
        };

        dtEntries[dtKey] = dtEntry;
        QATAR2022_MATCHES_BY_DATE.push(dtEntry);
      }

      match.dateParsed = moment(match.date);
      dtEntry.matches.push(match);

      QATAR2022_MATCHES_BY_ID[match.name] = match;
    });
  }

  for (let key in dtEntries) {
    let dtEntry = dtEntries[key];

    dtEntry.matches.sort(sortByDate);
  }

  QATAR2022_MATCHES_BY_DATE.sort(sortByDate);
};
buildMatchesByDate();
// DATE

export {
  WORLD_CUP_QATAR2022,
  QATAR2022_TEAMS_BY_ID,
  QATAR2022_STADIUMS_BY_ID,
  QATAR2022_MATCHES_BY_DATE,
  QATAR2022_MATCHES_BY_ID,
};
