import React, { Component } from "react";
import * as moment from "moment";

import {
  QATAR2022_TEAMS_BY_ID,
  QATAR2022_STADIUMS_BY_ID,
} from "../../data/cup";

import "./group.css";

class Match extends Component {
  constructor(props) {
    super(props);

    this.state = {
      homeScore: "",
      awayScore: "",
      score: null,
      disabled: true,

      ...(this.computeState(props) || {}),
    };

    this.handleGuess = this.handleGuess.bind(this);
  }

  componentWillReceiveProps(newProps) {
    let newState = this.computeState(newProps);
    this.setState(newState);
  }

  computeState(newProps) {
    let match = newProps.match;
    let matchState = newProps.matchState;
    const standingsMode = !!this.props.standingsMode;

    if ((!this.props.matchState || !this.state) && matchState && match) {
      let state = matchState[match.name];
      if (!match.dateParsed) match.dateParsed = moment(match.date);

      let nowPlus3 = moment().add(3, "hours");
      let lockedByTime = nowPlus3.isAfter(match.dateParsed);

      let newState = {
        disabled: lockedByTime && !standingsMode,
      };

      if (state) {
        let disabled = !!state.result && !standingsMode;
        newState = {
          disabled: disabled || lockedByTime,
        };

        if (state.guess)
          newState = {
            ...newState,
            homeScore: `${state.guess[0]}`,
            awayScore: `${state.guess[1]}`,
          };

        if (state.result && state.guess) {
          let mguess = state.guess;
          let mresult = state.result;
          let userScore = 0;

          if (mguess[0] === mresult[0] && mguess[1] === mresult[1])
            userScore += 9;
          if (mguess[0] === mresult[0] || mguess[1] === mresult[1])
            userScore += 3;
          if (mguess[0] + mguess[1] === mresult[0] + mresult[1]) userScore += 3;

          let winner =
            mresult[0] === mresult[1] ? 0 : mresult[0] < mresult[1] ? -1 : 1;
          let usrwinner =
            mguess[0] === mguess[1] ? 0 : mguess[0] < mguess[1] ? -1 : 1;
          if (winner === usrwinner) {
            userScore += 9;
          }

          newState["score"] = userScore;
        }

        if (state.result) {
          newState["result"] = state.result;
        }
      }

      return newState;
    }

    return null;
  }

  handleGuess(type, e) {
    let val = e.target.value.replace(/[^\d]/g, "");

    let homeScore = this.state.homeScore;
    let awayScore = this.state.awayScore;

    switch (type) {
      case "home":
        homeScore = val;
        if (val.length > 0 && awayScore === "") awayScore = "0";
        break;
      case "away":
        awayScore = val;
        if (val.length > 0 && homeScore === "") homeScore = "0";
        break;

      default:
    }

    this.setState({
      homeScore: homeScore,
      awayScore: awayScore,
    });

    // Enviando para o servidor
    let match = this.props.match;
    let matchState = this.props.matchState;
    let home = parseInt(homeScore, 10);
    let away = parseInt(awayScore, 10);

    if (matchState.sendGuess && !isNaN(home) && !isNaN(away)) {
      matchState.sendGuess(match.name, [home, away]);
    }
  }

  render() {
    let match = this.props.match;
    let homeTeam = QATAR2022_TEAMS_BY_ID[match.home_team];
    let awayTeam = QATAR2022_TEAMS_BY_ID[match.away_team];

    let homeTeamName = homeTeam.nome || homeTeam.name;
    let awayTeamName = awayTeam.nome || awayTeam.name;

    let stadium = QATAR2022_STADIUMS_BY_ID[match.stadium];
    let altText = [stadium.name, stadium.city].join("\n");

    let t = moment(match.date);
    let disabled = this.state.disabled;
    let score = this.state.score;

    let result = this.state.result;

    const standingsMode = !!this.props.standingsMode;

    return (
      <div className="match">
        <div className="panel">
          <span title={altText}>
            {t.format("DD/MM/YYYY HH:mm")}
            <br />
            <i>{stadium.name}</i>
          </span>
        </div>
        <div className="team home">
          <span>{homeTeamName}</span>
          <input
            type="text"
            className="guess"
            disabled={disabled}
            onChange={(e) => this.handleGuess("home", e)}
            value={this.state.homeScore}
          />
          <img
            className="flag"
            alt={homeTeamName}
            src={`/assets/flags/${homeTeam.iso2}.svg`}
          />
        </div>
        <div className="team away">
          <input
            type="text"
            className="guess"
            disabled={disabled}
            onChange={(e) => this.handleGuess("away", e)}
            value={this.state.awayScore}
          />
          <img
            className="flag"
            alt={awayTeamName}
            src={`/assets/flags/${awayTeam.iso2}.svg`}
          />
          <span className="team">{awayTeamName}</span>
        </div>
        <div className="panel panelr">
          {score !== null && !standingsMode ? (
            <div className="points">
              <b>{score}</b>
              <s>pontos</s>
            </div>
          ) : null}
        </div>

        {result ? (
          <div className="result">
            <div>{result.join(" x ")}</div>
          </div>
        ) : null}
      </div>
    );
  }
}

class MatchGroup extends Component {
  render() {
    let matches = this.props.matches || [];

    return (
      <div className="match-group">
        <h1 className="group-title">{this.props.title}</h1>

        {matches.map((match) => (
          <Match
            key={`match_${match.name}`}
            match={match}
            matchState={this.props.matchState}
            standingsMode={this.props.standingsMode}
          />
        ))}
      </div>
    );
  }
}

export { MatchGroup };
