import React, { Component } from 'react';

import { ConfigSettingsTab } from "./config-settings-tab";
import { ConfigUsersTab } from "./config-users-tab";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import "./config.css";

class ConfigForm extends Component {

    render() {
        return (
            <div className="config-form">
                <Tabs>
                    <TabList>
                        <Tab>Usuários</Tab>
                        <Tab>Comentários</Tab>
                        <Tab>Configurações</Tab>
                    </TabList>

                    <TabPanel>
                        <ConfigUsersTab />
                    </TabPanel>
                    <TabPanel>2</TabPanel>
                    <TabPanel>
                        <ConfigSettingsTab />
                    </TabPanel>
                </Tabs>
            </div>
        );
    }
}

export { ConfigForm };
