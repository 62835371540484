import React, { Component } from 'react';
import axios from "axios";

class ConfigSettingsTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            messages: {
                message: "",
                title: "",
                footer: "",
                mailContent: "",
                homeContent: "",
                rulesContent: "",
            },
            smtp: {
                server: "",
                from: "",
                user: "",
                password: "",
                editMode: false,
            },
        };

        this.ckbForceTLS = null;

        this.handleInputChange = this.handleInputChange.bind(this);
        this.saveSmtp = this.saveSmtp.bind(this);
        this.editSmtp = this.editSmtp.bind(this);
        this.saveMessages = this.saveMessages.bind(this);
    }

    componentWillMount() {
        this.queryServer();
    }

    queryServer(data = {}) {
        axios.post("/api/config/settings", data).then(res => {
            if (res.status !== 200) return;
            let d = res.data;

            if (d.status !== "OK") return;

            let messages = d.messages || {};
            let smtp = d.smtp;

            if (!smtp) {
                smtp = {
                    editMode: true,
                };
            }
            else
                smtp["editMode"] = this.state.smtp.editMode;

            if (this.ckbForceTLS)
                this.ckbForceTLS.checked = !!smtp["forceTLS"];

            smtp["password"] = smtp["password"] || "";

            this.setState({
                messages: messages,
                smtp: smtp,
            });
        });
    }

    handleInputChange(group, field, e) {
        let groupInst = this.state[group];
        let newState = {
            ...groupInst,
            [field]: e.target.value,
        };

        this.setState({
            [group]: newState,
        });
    }

    saveSmtp() {
        let data = { ...this.state.smtp };
        delete data["editMode"];

        this.editSmtp(false);
        this.queryServer({ smtp: data });
    }

    editSmtp(doEdit = true) {
        this.setState({
            smtp: {
                ...this.state.smtp,
                editMode: doEdit,
            }
        });
    }

    saveMessages() {
        this.queryServer({ messages: this.state.messages });
    }

    render() {
        let smtpReadOnlyMode = !this.state.smtp.editMode;
        let messages = this.state.messages;

        return (
            <div className="columns">
                <div>
                    <h3>Geral</h3>

                    <div className="form-control">
                        <label>Aviso</label>
                        <input type="text"
                            value={ messages.message }
                            onChange={ e => this.handleInputChange("messages", "message", e) } />
                    </div>
                    <div className="form-control">
                        <label>Título</label>
                        <input type="text"
                            value={ messages.title }
                            onChange={ e => this.handleInputChange("messages", "title", e) } />
                    </div>
                    <div className="form-control">
                        <label>Rodapé</label>
                        <input type="text"
                            value={ messages.footer }
                            onChange={ e => this.handleInputChange("messages", "footer", e) } />
                    </div>
                    <div className="form-control">
                        <label>Corpo do e-mail de convite</label>
                        <textarea
                            value={ messages.mailContent }
                            onChange={ e => this.handleInputChange("messages", "mailContent", e) } />
                    </div>
                    <div className="form-control">
                        <label>Corpo da homepage</label>
                        <textarea rows="5"
                            value={ messages.homeContent }
                            onChange={ e => this.handleInputChange("messages", "homeContent", e) } />
                    </div>
                    <div className="form-control">
                        <label>Regras</label>
                        <textarea rows="5"
                            value={ messages.rulesContent }
                            onChange={ e => this.handleInputChange("messages", "rulesContent", e) } />
                    </div>

                    <div className="actions">
                        <button className="btn" onClick={ this.saveMessages }>Salvar</button>
                    </div>
                </div>
                <div>
                    <h3>SMTP</h3>

                    <div className="form-control">
                        <label>Servidor</label>
                        <input type="text"
                            value={ this.state.smtp.server } disabled={ smtpReadOnlyMode }
                            onChange={ e => this.handleInputChange("smtp", "server", e) } />
                    </div>
                    <div className="form-control">
                        <label>Email (from)</label>
                        <input type="text"
                            value={ this.state.smtp.from } disabled={ smtpReadOnlyMode }
                            onChange={ e => this.handleInputChange("smtp", "from", e) } />
                    </div>
                    <div className="form-control">
                        <label>Usuário</label>
                        <input type="text"
                            value={ this.state.smtp.user } disabled={ smtpReadOnlyMode }
                            onChange={ e => this.handleInputChange("smtp", "user", e) } />
                    </div>
                    <div className="form-control">
                        <label>Senha</label>
                        <input type="password" autoComplete="new-password"
                            value={ this.state.smtp.password } disabled={ smtpReadOnlyMode }
                            onChange={ e => this.handleInputChange("smtp", "password", e) } />
                    </div>
                    <div className="checkbox">
                        <input type="checkbox" ref={ e => this.ckbForceTLS = e }
                            disabled={ smtpReadOnlyMode } />
                        <label>Forçar TLS</label>
                    </div>

                    <div className="actions">
                        <button className="btn" disabled={ !smtpReadOnlyMode } onClick={ this.editSmtp }>Alterar</button>
                        <button className="btn" disabled={ !smtpReadOnlyMode || true }>Testar</button>
                        <button className="btn" disabled={ smtpReadOnlyMode } onClick={ this.saveSmtp }>Salvar</button>
                    </div>
                </div>
            </div>
        );
    }
}

export { ConfigSettingsTab }
