import React, { Component, Fragment } from "react";
import { withRouter, NavLink } from "react-router-dom";
import axios from "axios";

import { setLoginInfo, setStateInfo } from "../data/session";

const MODE_LOGIN = 1;
const MODE_SIGNUP = 2;
const MODE_CHANGEPW = 3;
const MODE_REGISTER = 4;

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = this.getModeState(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.mode === this.state.mode) return;

    let newState = this.getModeState(newProps);
    this.setState(newState);
  }

  getModeState(props) {
    let mode = MODE_LOGIN;

    let m = props.match || {};
    let parms = m.params || {};
    let uid = parms["uid"];
    let key = parms["key"];

    if (props.mode) {
      mode = props.mode;
    } else {
      if (uid && key) {
        mode = MODE_SIGNUP;
        this.loadSignupInfo(uid, key);
      }
    }

    return {
      mode: mode,
      uid: uid,
      key: key,
      fullName: "",
      email: "",
      current_password: "",
      password: "",
      password2: "",
      working: mode === MODE_SIGNUP,
    };
  }

  onSubmit(e) {
    e && e.preventDefault();

    if (!this.canSubmit) return;

    if (this.state.mode === MODE_REGISTER) {
      if (!this.state.email.match(/(@tvtem\.com|@temmais\.com)$/)) {
        alert("É necessário fornecer um e-mail @tvtem.com ou @temmais.com");
        return;
      }
    }

    this.setState({
      working: true,
    });

    let state = this.state;
    let history = this.props.history;

    let url = "/api/login";
    let data = {
      username: state.email,
      password: state.password,
    };

    let redirUrl = "/";
    switch (state.mode) {
      case MODE_CHANGEPW:
        url = "/api/profile/changepw";
        redirUrl = "/profile";
        data = {
          current_password: state.current_password,
          password: state.password,
        };
        break;

      case MODE_SIGNUP:
        url = "/api/signup/v";
        data = {
          uid: state.uid,
          key: state.key,
          password: state.password,
        };
        break;

      case MODE_REGISTER:
        url = "/api/signup/r";
        data = {
          fullName: state.fullName,
          email: state.email,
        };
        break;

      default:
    }

    axios.post(url, data).then((r) => {
      if (r.status !== 200) return;

      let d = r.data;
      switch (d.status) {
        case "OK":
          if (state.mode === MODE_REGISTER) {
            alert(
              "Em breve você receberá um e-mail para confirmar o cadastro."
            );
            history.replace(redirUrl);
            return;
          }

          let loggedUser = { ...d };
          delete loggedUser["status"];
          setLoginInfo(loggedUser);

          // Adicionando essa chamada para que o ranking seja corretamente carregado
          axios.post("/api/state").then((r) => {
            if (r.status !== 200) return;

            let d = r.data;
            setStateInfo(d);
          });

          history.replace(redirUrl);
          return;

        case "InvalidPassword":
          alert("Senha inválida");
          break;

        case "InvalidUser":
          alert("E-mail inválido");
          break;

        default:
      }

      this.setState({ working: false });
    });
  }

  loadSignupInfo(uid, key) {
    axios
      .post("/api/signup/v", {
        uid: uid,
        key: key,
      })
      .then((r) => {
        if (r.status !== 200) return;

        let d = r.data;
        this.setState({
          email: d.email,
          working: false,
        });
      });
  }

  changeHandler(key, e) {
    let val = e.target.value;

    this.setState({
      [key]: val,
    });
  }

  get canSubmit() {
    let state = this.state;

    let result = !state.working && state.email;

    switch (state.mode) {
      case MODE_CHANGEPW:
        result =
          !state.working &&
          state.current_password &&
          state.password &&
          state.password2 &&
          state.password === state.password2;
        break;

      case MODE_SIGNUP:
        result =
          result &&
          state.uid &&
          state.key &&
          state.password &&
          state.password2 &&
          state.password === state.password2;
        break;

      case MODE_REGISTER:
        result = !state.working && state.fullName && state.email;
        break;

      default:
        result = result && state.password;
        break;
    }

    return result;
  }

  render() {
    let state = this.state;
    let signupMode = state.mode === MODE_SIGNUP;
    let signinMode = state.mode === MODE_LOGIN;
    let changepwMode = state.mode === MODE_CHANGEPW;
    let registerMode = state.mode === MODE_REGISTER;
    let working = state.working;

    let title = "Login";
    if (changepwMode) title = "Alterar senha";
    if (registerMode) title = "Cadastro";
    if (signupMode) title = "Defina sua senha";

    return (
      <Fragment>
        <div className="box-container">
          <div className="App-intro">{title}</div>
          <div className="box">
            <form onSubmit={(e) => this.onSubmit(e)}>
              {!registerMode ? null : (
                <label>
                  Nome completo
                  <input
                    type="text"
                    disabled={working}
                    autoComplete="name"
                    value={state.fullName}
                    onChange={(e) => this.changeHandler("fullName", e)}
                  />
                </label>
              )}

              {changepwMode ? null : (
                <Fragment>
                  <label>
                    Email
                    <input
                      type="email"
                      style={registerMode ? { marginBottom: 0 } : {}}
                      disabled={working || signupMode}
                      autoComplete="email"
                      value={state.email}
                      onChange={(e) => this.changeHandler("email", e)}
                    />
                  </label>
                  <s className={registerMode ? "info" : "hidden"}>
                    É necessário informar um email <br />
                    @tvtem.com ou @temmais.com
                  </s>
                </Fragment>
              )}

              {changepwMode ? (
                <label>
                  Senha atual
                  <input
                    type="password"
                    disabled={working}
                    value={state.current_password}
                    onChange={(e) => this.changeHandler("current_password", e)}
                  />
                </label>
              ) : null}

              {registerMode ? null : (
                <Fragment>
                  <label>
                    {changepwMode ? "Nova senha" : "Senha"}
                    <input
                      type="password"
                      disabled={working}
                      value={state.password}
                      onChange={(e) => this.changeHandler("password", e)}
                    />
                  </label>

                  {signupMode || changepwMode ? (
                    <label>
                      Confirmação de Senha
                      <input
                        type="password"
                        disabled={working}
                        value={state.password2}
                        onChange={(e) => this.changeHandler("password2", e)}
                      />
                    </label>
                  ) : null}
                </Fragment>
              )}

              <div className="form-actions">
                <button type="submit" disabled={!this.canSubmit}>
                  {signupMode || registerMode ? "Salvar" : "Entrar"}
                </button>
              </div>
            </form>
          </div>

          <br />
          {!signinMode ? null : (
            <div className="login-message">
              Se você ainda não se cadastrou,{" "}
              <NavLink to="/cadastro">clique aqui</NavLink>
            </div>
          )}
        </div>
        <br />
        {changepwMode ? (
          <NavLink to="/profile" className="btn btn-back">
            Voltar
          </NavLink>
        ) : null}
      </Fragment>
    );
  }
}

const LoginFormWithRouter = withRouter(LoginForm);
export { LoginFormWithRouter as LoginForm };
