import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import axios from "axios";

import "./ranking-page.css";

export class RankingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ranking: []
        };

        axios.post("/api/state/ranking").then(res => {
            if (res.status !== 200) return;

            this.setState({
                ranking: res.data["ranking"] || [],
            });
        });

    }

    render() {
        return (
<div className="profile-form">
    <h1 className="group-title">Ranking</h1>

    <ul className="user-ranking">
        { this.state.ranking.map(rankingEntry => (
            <li key={ `rnk_${ rankingEntry.id }` }>
                <b>{ rankingEntry.score } pontos</b>
                <i>{ rankingEntry.score_position }.</i>
                <span>{ rankingEntry["user.fullName"] }</span>
            </li>
        ))}
    </ul>

    <NavLink to="/" className="btn btn-back">Voltar</NavLink>
    
</div>
        );
    }
}
