import React, { Component, Fragment } from 'react';
import axios from "axios";
import moment from "moment";

import { getLoginInfoWithCallback } from "../data/session";

const CHAT_POOL_INTERVAL = 10 * 1000; // 10 segundos
const CHAT_MAX_MESSAGES = 50;

// TODO: Rolagem automática do chat ao chegar nova mensagem

class Chat extends Component {
    constructor(props) {
        super(props);

        this.state = {
            latestId: -1,
            comments: [],
            message: "",
            isLoggedIn: false,
        };
        this.tmrUpdate = null;
        this.ul = null;

        this.sendMessage = this.sendMessage.bind(this);
        this.updateChat = this.updateChat.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.handleMessageKeyDown = this.handleMessageKeyDown.bind(this);

        getLoginInfoWithCallback(this.setLoginInfo.bind(this), "chat");
    }

    setLoginInfo(user) {
        this.setState({
            isLoggedIn: !!user,
        });
    }

    clearUpdateTimer() {
        if (this.tmrUpdate) {
            clearInterval(this.tmrUpdate);
            this.tmrUpdate = null;
        }
    }

    componentWillMount() {
        this.clearUpdateTimer();

        this.updateChat();
        this.tmrUpdate = setInterval(this.updateChat, CHAT_POOL_INTERVAL);
    }

    componentWillUnmount() {
        this.clearUpdateTimer();
    }

    updateChat(message = null) {
        axios.post("/api/state/chat", {
            message: message,
            latestId: this.state.latestId,
        }).then(res => {
            if (res.status !== 200) return;

            let d = res.data;
            if (d.status !== "OK") return;

            let newComments = d.comments||[];
            if (newComments.length === 0) return;

            // Adicionar apenas as mensagens que ainda não temos
            let currentCommentIds = this.state.comments.map(c => c.id);
            let comments = [];

            newComments.forEach(c => {
                if (currentCommentIds.includes(c.id)) return;
                comments.push({
                    ...c,
                    createdAt: moment(c.createdAt).format("DD/MM/YYYY HH:mm"),
                });
            });

            // Os comentários sempre vão estar ordenados do mais antigo para o mais novo
            comments.push(...this.state.comments);
            comments.sort((a, b) => a.id - b.id);

            if (comments.length > CHAT_MAX_MESSAGES)
                comments.splice(0, comments.length - CHAT_MAX_MESSAGES);

            let latestId = -1;
            comments.forEach(c => {
                if (c.id > latestId) 
                    latestId = c.id;
            });

            this.setState({
                comments: comments,
                latestId: latestId,
            });

            let ul = this.ul;
            if (ul)
                setTimeout(() => ul.scrollTop = ul.scrollHeight, 40);
        });
    }

    handleMessageChange(e) {
        let v = e.target.value;
        this.setState({
            message: v
        });
    }

    handleMessageKeyDown(e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            this.sendMessage();
        }
    }

    sendMessage() {
        this.updateChat(this.state.message);
        this.setState({
            message: "",
        });
    }

    render() {
        let comments = this.state.comments;
        let disableSend = !this.state.isLoggedIn;

        return (
            <Fragment>
                <h1 className="group-title">Comentários</h1>
                <ul className="comments" ref={ e => this.ul = e }>
                    { comments.map(comment => (
                    <li key={ comment.id }>
                        { comment.message }
                        <i>{ comment.userName }, { comment.createdAt }</i>
                    </li>
                    ))}
                </ul>
                <div className="comment-form">
                    <textarea placeholder="Digite sua mensagem aqui..." rows="2" 
                        disabled={ disableSend }
                        value={ this.state.message } onChange={ this.handleMessageChange }
                        onKeyDown={ this.handleMessageKeyDown }></textarea>
                    <div>
                        <button onClick={ this.sendMessage }
                            disabled={ disableSend }>Enviar</button>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export { Chat };
