import React, { Component, Fragment } from 'react';
import axios from "axios";

const REGEX_EMAIL = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
const REGEX_FORMAT_EMAIL1 = /^(.+?)\s*[<(]\s*(.+?)\s*[>)]$/;
const REGEX_FORMAT_EMAIL2 = /^(.+?[a-z0-9])\s+([a-z][^\s]+)$/i;

const statusToString = (status) => {
    switch (status) {
        case 1:
            return "Convidado";
        case 2:
            return "Cadastrado";

        default:
            return "Envio pendente";
    }
}

class ConfigUsersTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            newUsersTxt: "",
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.saveNewUsers = this.saveNewUsers.bind(this);
    }

    componentWillMount() {
        this.queryServer();
    }

    queryServer(data = {}) {
        axios.post("/api/config/users", data).then(res => {
            if (res.status !== 200) return;
            let d = res.data;

            if (d.status !== "OK") return;

            this.setState({
                users: d.users,
            });
        });
    }

    handleInputChange(field, e) {
        this.setState({
            [field]: e.target.value,
        });
    }

    saveNewUsers() {
        let inputText = this.state.newUsersTxt;
        let users = this.parseUsersToAdd(inputText);

        this.setState({
            newUsersTxt: "",
        });

        this.queryServer({
            usersToAdd: users
        });
    }

    parseUsersToAdd(text) {
        let users = [];
        
        // Separar entradas em [\n,;]
        let lines = text.split(/[\n,;]+/);

        // Formatos possíveis:
        //   nome <email>, nome (email), nome\s+email
        let formats = [ REGEX_FORMAT_EMAIL1, REGEX_FORMAT_EMAIL2 ];

        lines.forEach(line => {
            let name = null;
            let email = null;

            line = line.trim();

            formats.some(re => {
                let m = line.match(re);
                if (m && REGEX_EMAIL.test(m[2])) {
                    name = m[1];
                    email = m[2];

                    return true;
                }

                return false;
            });

            if (name && email) {
                users.push({
                    name: name,
                    email: email,
                });
            }
        })

        return users;
    }

    render() {
        let users = this.state.users;

        return (
            <Fragment>
                <ul className="users">
                    { users.map(user =>
                    <li key={ `user_${ user.id }` }>
                        <b>{ user.name }</b>
                        <s>{ user.email }</s>
                        <i>{ statusToString(user.status) }</i>
                    </li>
                    ) }
                </ul>

                <div className="add-users">
                    <textarea placeholder="João da silva <jsilva@gmail.com>"
                        value={ this.state.newUsersTxt }
                        onChange={ e => this.handleInputChange("newUsersTxt", e) }></textarea>
                    <button className="btn" onClick={ this.saveNewUsers }>Adicionar</button>
                </div>
            </Fragment>
        );
    }
}

export { ConfigUsersTab }
