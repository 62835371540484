import * as axios from "axios";

let gUser = null;
let gCallback = {};

let gRanking = [];
let gRankingCallback = null;

const DEFAULT_TITLE = "Bolão TV TEM";
const DEFAULT_MESSAGE = " ";
const DEFAULT_FOOTER = "2022 - Todos os direitos reservados";

let gMessages = {
  title: DEFAULT_TITLE,
  message: DEFAULT_MESSAGE,
  footer: DEFAULT_FOOTER,
};

const doLogout = (history, e) => {
  e && e.preventDefault();

  axios.post("/api/logout").then(() => {
    setLoginInfo(null);
    history && history.replace("/login");
  });
};

const setLoginInfo = (user) => {
  gUser = user;

  for (let k in gCallback) {
    let cb = gCallback[k];
    if (cb) cb(user);
  }
};

const setStateInfo = (state) => {
  setLoginInfo(state.user);

  if (state.messages) {
    let messages = state.messages;
    let title = messages["title"] || DEFAULT_TITLE;
    let message = messages["message"] || DEFAULT_MESSAGE;
    let footer = messages["footer"] || DEFAULT_FOOTER;

    gMessages = {
      title: title,
      message: message,
      footer: footer,
      homeContent: messages["homeContent"] || "",
      rulesContent: messages["rulesContent"] || "",
    };

    document.title = title;
  }

  if (state.ranking) {
    gRanking = state.ranking;
    if (gRankingCallback) gRankingCallback(gRanking);
  }
};

const getLoginInfo = () => {
  if (!gUser) return null;
  return { ...gUser };
};

const getLoginInfoWithCallback = (cb, name = "*") => {
  gCallback[name] = cb;

  if (cb) setTimeout(() => cb(gUser), 16);
};

const getRankingWithCallback = (cb) => {
  gRankingCallback = cb;

  if (cb) setTimeout(() => cb(gRanking), 16);
};

const getMessages = () => {
  return { ...gMessages };
};

export {
  doLogout,
  setLoginInfo,
  setStateInfo,
  getLoginInfo,
  getLoginInfoWithCallback,
  getRankingWithCallback,
  getMessages,
};
