import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';

import { getLoginInfoWithCallback } from "../data/session";
import * as axios from "axios";

import "./profile.css";

class ProfilePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {},
            guesses_remaining: 48,
            points: "-",
            tshirt_size: null,
        };

        this.loadUserProfile();
        getLoginInfoWithCallback(this.setUserInfo.bind(this), "profile");

        this.tShirtSizeChanged = this.tShirtSizeChanged.bind(this);
    }

    setUserInfo(user) {
        this.setState({ user: user || {} });
    }

    loadUserProfile() {
        axios.post("/api/state/profile").then(res => {
            if (res.status !== 200) return;

            let d = res.data;
            if (d.status !== "OK") return;

            let state = this.state;
            this.setState({
                guesses_remaining: typeof(d.guesses_remaining) === "number" ? d.guesses_remaining : state.guesses_remaining,
                points: typeof(d.points) === "number" ? d.points : state.points,
                tshirt_size: d.tshirt_size || state.tshirt_size,
            });
        });
    }

    tShirtSizeChanged(e) {
        let ckb = e.target;
        if (!ckb.checked)
            return;

        this.setState({
            tshirt_size: ckb.value,
        });

        axios.post("/api/state/profile/update", {
            tshirt_size: ckb.value,
        });
    }

    render() {
        let tshirtSizes = [
            { key: "P", value: "Pequena" },
            { key: "M", value: "Média" },
            { key: "G", value: "Grande" },
        ];

        let user = this.state.user;
        let points = this.state.points;
        let guesses_remaining = this.state.guesses_remaining;
        let tshirt = this.state.tshirt_size;

        return (
<div className="profile-form">
    <h1 className="group-title">Perfil</h1>
    <dl>
        <dd>
            <div className="profile-points">
                <b>{ points }</b>
                <s>PONTOS</s>
            </div>
        </dd>
        <dd className="user-info">
            <ul>
                <li>{ user.fullName }</li>
                <li>{ user.email }</li>
                <li style={ { color: "#327abe" } }>{ guesses_remaining } palpites restantes</li>

                <li>
                    <NavLink to="/profile/change_password" className="btn">Alterar senha</NavLink>
                </li>
            </ul>

        </dd>
        <dd className="separator"></dd>
        <dd className="hidden">
            <img src="/images/tshirt_150.png" alt="Camiseta" />
        </dd>
        <dd className="hidden">
            <div className="vcenter">
            { tshirtSizes.map(t => 
            <div key={ `sz_${ t.key }` } className="form-check">
                <input className="form-check-input" type="radio" 
                    checked={ tshirt === t.key } onChange={ this.tShirtSizeChanged }
                    name="tshirt-size" id={ `tsz_${ t.key }` } value={ t.key } />
                <label className="form-check-label" htmlFor={ `tsz_${ t.key }` }>
                    { t.value }
                </label>
            </div>
            )}
            </div>
        </dd>
    </dl>

    <NavLink to="/" className="btn btn-back">Voltar</NavLink>
    
</div>
        );
    }
}

const ProfilePageWithRouter = withRouter(ProfilePage);
export { ProfilePageWithRouter as ProfilePage };
