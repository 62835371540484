import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Converter } from "showdown";

import "./home-page.css";

export class HomePage extends Component {
    constructor(props) {
        super(props);

        let conv = new Converter();
        this.state = {
            introHtml: { __html: conv.makeHtml(props.message || "") }
        };
    }

    componentWillReceiveProps(props) {
        let conv = new Converter();
        let introHtml = { __html: conv.makeHtml(props.message || "") };

        this.setState({
            introHtml: introHtml,
        });
    }

    render() {
        let userLoggedIn = !!this.props.userLoggedIn;
        return <div className="home-page">
            <div dangerouslySetInnerHTML={this.state.introHtml}>
            </div>

            {this.props.showBackButton ? (
                <div className="profile-form">
                    <NavLink to="/" className="btn btn-back">Voltar</NavLink>
                </div>
            ) : (
                    <div className="c">
                        {userLoggedIn ? (
                            <NavLink className="btn" to="/palpites">Deixe os seus palpites</NavLink>
                        ) : (
                            <NavLink className="btn" to="/login">Clique aqui para entrar</NavLink>
                        )}

                        <NavLink className="btn" to="/regulamento">Regulamento</NavLink>
                    </div>
                )}
        </div>;
    }
}
/*
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ut viverra lectus.
                Praesent in convallis felis. Phasellus in justo at felis finibus consectetur a vitae lorem.
                Fusce arcu diam, ultrices nec facilisis in, sodales non risus. Nullam consectetur magna non ante bibendum tristique.
                Cras egestas fringilla neque ac interdum. Aliquam erat volutpat. Interdum et malesuada fames ac ante ipsum primis in faucibus.
                Nam gravida risus id nulla sodales, tincidunt placerat lacus accumsan. Duis lorem velit, vehicula a erat id, pellentesque sagittis purus.
            </p>

            <p>
                Aliquam vehicula quis nisl nec ullamcorper. Duis semper leo leo, ut varius augue posuere nec.
                Phasellus urna tortor, semper cursus orci ut, placerat tristique turpis. Nam interdum posuere nulla sed elementum.
                Morbi tempor lorem in nisl iaculis laoreet. Nam nec turpis a ligula auctor lacinia a consectetur ipsum.
                Aliquam nunc ante, scelerisque nec risus vitae, ultrices cursus quam. Cras malesuada ipsum at dapibus hendrerit.
                Integer lobortis sapien nec orci tristique fringilla. Nam nunc lectus, rhoncus non blandit eget, malesuada in ligula.
            </p>
*/
