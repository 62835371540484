import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import * as axios from "axios";

import { WORLD_CUP_QATAR2022, QATAR2022_MATCHES_BY_DATE } from "../data/cup";
import { MatchGroup } from "./matches/group";

// A cada 2 segundos o cliente irá tentar o envio dos palpites
// Se houver alterações, guardar para o próximo ciclo

// Aqui também é mantido o MatchState[], contendo todos os palpites
// e resultados dos jogos

class MainPage extends Component {
  constructor(props) {
    super(props);

    this.guessesTimer = null;
    this.pendingGuesses = [];

    const standingsMode = parseInt(props.resultados, 10) > 0;
    this.state = {
      matchState: null,
      displayByDate: true,
      canSave: false,
      standingsMode,
    };

    this.setViewingMode = this.setViewingMode.bind(this);
    this.doSave = this.doSave.bind(this);

    axios.post("/api/state/profile").then((res) => {
      if (res.status !== 200) return;
      let data = res.data;

      if (data.status === "OK") {
        let matches = data.matches || [];
        let matchesById = {
          sendGuess: this.sendGuess.bind(this),
        };

        matches.forEach((m) => {
          const otherProps = {};
          if (standingsMode && m.result) {
            otherProps.guess = [...m.result];
          }

          matchesById[m.id] = {
            ...m,
            ...otherProps,
          };
        });
        this.setState({ matchState: matchesById });
      }
    });
  }

  sendGuess(matchId, guess) {
    let match = {
      id: matchId,
      guess: guess,
    };

    let newPendingGuesses = [...this.pendingGuesses];
    let matchIndex = newPendingGuesses.findIndex((m) => m.id === matchId);

    if (matchIndex < 0) newPendingGuesses.push(match);
    else newPendingGuesses[matchIndex] = match;

    this.pendingGuesses = newPendingGuesses;

    if (this.state.matchState) {
      let newMatchState = {
        ...this.state.matchState,
        [matchId]: {
          id: matchId,
          guess: guess,
        },
      };

      this.setState({
        matchState: newMatchState,
        canSave: true,
      });
    }
  }

  componentDidMount() {
    this.clearGuessesTimer();

    let tmrState = {
      pendingGuesses: null,
      sent: false,
    };

    if (!this.state.standingsMode) {
      this.guessesTimer = setInterval(() => {
        if (this.pendingGuesses !== tmrState.pendingGuesses) {
          tmrState.pendingGuesses = this.pendingGuesses;
          tmrState.sent = false;
        } else if (!tmrState.sent) {
          tmrState.sent = true;
          if (tmrState.pendingGuesses.length > 0) this.sendGuessesToServer();
        }
      }, 2000);
    }
  }

  componentWillUnmount() {
    this.clearGuessesTimer();
    this.sendGuessesToServer();
  }

  clearGuessesTimer() {
    if (this.guessesTimer) {
      clearInterval(this.guessesTimer);
      this.guessesTimer = null;
    }
  }

  sendGuessesToServer(showMessage = false) {
    const { standingsMode } = this.state;
    let guessesToSend = this.pendingGuesses || [];
    this.pendingGuesses = [];

    const message = () =>
      showMessage &&
      alert(`${standingsMode ? "Resultados" : "Palpites"} salvos com sucesso`);

    if (guessesToSend.length === 0) {
      message();
      return;
    }

    console.log("Sending guesses do server: ", guessesToSend.length);

    const url = standingsMode ? "/api/standings" : "/api/state/profile/update";
    axios
      .post(url, {
        matches: guessesToSend,
      })
      .then((r) => {
        if (r.status === 200) {
          message();
          return;
        }

        // Adicionando de volta aos pendentes
        this.pendingGuesses = [...this.pendingGuesses, ...guessesToSend];
      });
  }

  setViewingMode(displayByDate) {
    this.setState({
      displayByDate: !!displayByDate,
    });
  }

  doSave() {
    this.setState({
      canSave: false,
    });

    this.sendGuessesToServer(true);
  }

  render() {
    let isDtMode = this.state.displayByDate;
    const { standingsMode } = this.state;

    return (
      <Fragment>
        <div className="guess-actions">
          <button
            className="bt pull-right"
            onClick={this.doSave}
            disabled={!this.state.canSave}
          >
            Salvar
          </button>

          <button
            className={`bt dt ${isDtMode ? "active" : ""}`}
            onClick={() => this.setViewingMode(true)}
            title="Exibir jogos por data"
          >
            &nbsp;
          </button>
          <button
            className={`bt grp ${isDtMode ? "" : "active"}`}
            onClick={() => this.setViewingMode(false)}
            title="Exibir jogos por grupos"
          >
            &nbsp;
          </button>

          {standingsMode && "Resultados dos jogos"}
        </div>
        {this.renderGroups()}
      </Fragment>
    );
  }

  renderGroups() {
    if (this.state.displayByDate) {
      return QATAR2022_MATCHES_BY_DATE.map((d) => (
        <MatchGroup
          key={`dt_${d.dateKey}`}
          title={d.dateParsed.format("dddd, D [de] MMMM [de] YYYY")}
          matches={d.matches}
          matchState={this.state.matchState}
          standingsMode={this.state.standingsMode}
        />
      ));
    }

    let groups = [];

    for (let g in WORLD_CUP_QATAR2022.groups) {
      groups.push({
        key: g,
        group: WORLD_CUP_QATAR2022.groups[g],
      });
    }

    return groups.map((g) => (
      <MatchGroup
        key={`group_${g.key}`}
        title={`Grupo ${g.key.toUpperCase()}`}
        matches={g.group.matches}
        matchState={this.state.matchState}
        standingsMode={this.state.standingsMode}
      />
    ));
  }
}

const MainPageWithRouter = withRouter(MainPage);
export { MainPageWithRouter as MainPage };
